import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CloseButton } from 'react-bootstrap';

import { useAuth } from '../../context/Auth';
import { adminFetchUnapprovedFeed } from '../../services/admin-api.ts';

import UnapprovedCard from '../../components/admin/unapproved-card';

import './style.css';

function useFetchData() {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { authToken } = useAuth();

  useEffect(() => {
    setLoading(true);
    adminFetchUnapprovedFeed(authToken)
      .then((res) => {
        setLoading(false);
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        setData(body);
      })
      .catch(() => {
        console.log('error!'); // TODO
      });
  }, [authToken]);
  return {
    isLoading,
    data,
  };
}

function AdminHome() {
  const { isLoading, data } = useFetchData();
  const history = useHistory();

  const closeForm = () => {
    history.goBack();
  };

  return (
    <div className="adminContainer">
      <CloseButton onClick={closeForm} />
      <h3>Admin Home</h3>
      { isLoading && <span>Loading...</span> }
      { !isLoading && data.length === 0 && <span>(geen berichten om te checken)</span> }
      { !isLoading && data.length > 0 && (
        <div className="unapprovedContainer">
          { data.map((item) => (
            <UnapprovedCard key={item.id} item={item} />
          ))}
        </div>
      )}
    </div>
  );
}

export default AdminHome;
