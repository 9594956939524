import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { initialState, feedReducer } from './FeedReducer';

const Store = React.createContext();
Store.displayName = 'MyMokaStore';

export const useStore = () => React.useContext(Store);

export function StoreProvider({ children }) {
  const [globalState, dispatch] = React.useReducer(feedReducer, initialState);
  const value = useMemo(() => ([globalState, dispatch]), [globalState]);
  return (
    <Store.Provider value={value}>{children}</Store.Provider>
  );
}

StoreProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
