import React from 'react';
import PropTypes from 'prop-types';

import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';

import './style.css';

function ProductCard(props) {
  const {
    id,
    title,
    description,
    imageUrl,
  } = props;

  return (
    <Card key={id} className="productCard">
      { imageUrl && (
      <Card.Img variant="top" src={imageUrl} />
      )}
      { !imageUrl && (
      <Card.Header><FontAwesomeIcon icon={faImage} size="6x" /></Card.Header>
      )}
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        { description && <Card.Text>{description}</Card.Text> }
      </Card.Body>
    </Card>
  );
}

ProductCard.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
};

ProductCard.defaultProps = {
  description: undefined,
  imageUrl: undefined,
};

export default ProductCard;
