export const brewingMethods = {
  espresso: 'Espresso',
  filter: 'Filter',
  bialetti: 'Bialetti',
  other: 'Anders',
};

export const roastTypes = {
  espresso: 'Espresso Roast',
  filter: 'Filter Roast',
  other: 'Overig',
};
