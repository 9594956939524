import React, {
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import { logger } from '../logger';

const authTokenKey = 'authToken';
const authRolesKey = 'authRoles';

const AuthContext = createContext({
  authToken: null,
  authRoles: [],
  setAuthResult: () => null,
  clearAuthToken: () => null,
});

function AuthProvider({ children }) {
  const [authToken, setAuthToken] = useState(null);
  const [authRoles, setAuthRoles] = useState([]);

  const saveAuthResult = (result) => {
    const { token, roles } = result;
    setAuthToken(token);
    setAuthRoles(roles);
    logger('saving auth result to local storage');
    localStorage.setItem(authTokenKey, token);
    localStorage.setItem(authRolesKey, roles);
  };

  const clearAuthResult = () => {
    setAuthToken(undefined);
    setAuthRoles([]);
    logger('clearing auth local storage');
    localStorage.removeItem(authTokenKey);
    localStorage.removeItem(authRolesKey);
  };

  // Read tokens from localstorage.
  // TODO verify token still valid?
  if (authToken === null && localStorage.getItem(authTokenKey)) {
    logger('loading auth token from local storage');
    setAuthToken(localStorage.getItem(authTokenKey));
  }
  if (authRoles.length === 0 && localStorage.getItem(authRolesKey)) {
    logger('loading auth roles from local storage');
    setAuthRoles(localStorage.getItem(authRolesKey));
  }

  const value = useMemo(() => ({
    authToken,
    authRoles,
    saveAuthResult,
    clearAuthResult,
  }), [authToken, authRoles]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used with an AuthProvider');
  }
  return context;
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { AuthContext, AuthProvider, useAuth };
