import { React } from 'react';
import { faHome, faPlus, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { useAuth } from '../../context/Auth';

import './style.css';

function Footer() {
  const { authRoles } = useAuth();
  const isAdmin = authRoles.includes('admin');

  // Add logic to enable experiment for some users.
  const isExperimentActive = true;
  const plusButtonUrl = isExperimentActive ? 'check-in' : 'new-coffee';

  return (
    <div className="app-footer">
      <div className="row">
        <div className="column">
          <Link to="/">
            <FontAwesomeIcon icon={faHome} size="lg" />
          </Link>
        </div>
        <div className="column">
          <Link to={plusButtonUrl}>
            <FontAwesomeIcon icon={faPlus} size="lg" />
          </Link>
        </div>
        {isAdmin && (
        <div className="column">
          <Link to="admin">
            <FontAwesomeIcon icon={faUserCircle} size="lg" />
          </Link>
        </div>
        )}
      </div>
    </div>
  );
}

export default Footer;
