import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Image,
  Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import Rating from '../../components/Rating';
import CoffeeListItem from '../../components/product-card';
import { useAuth } from '../../context/Auth';
import { fetchBrandDetail } from '../../services/api.ts';

import './style.css';

function useFetchData() {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { authToken } = useAuth();
  const { brandId } = useParams();
  useEffect(() => {
    setLoading(true);
    fetchBrandDetail(authToken, brandId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        setLoading(false);
        setData(body);
      })
      .catch(() => {
        console.log('error!'); // TODO
      });
  }, [authToken, brandId]);
  return {
    isLoading,
    data,
  };
}

function BrandDetail() {
  const { isLoading, data } = useFetchData();
  const history = useHistory();

  const {
    brandName, brandLocation, brandBio, brandAverageRating, brandImageUrl, products,
  } = data;

  const coffeeItems = [];
  if (products) {
    coffeeItems.push(products.map((item) => (
      <li key={item.coffeeId}>
        <CoffeeListItem
          id={item.coffeeId}
          title={item.coffeeName}
          description={item.coffeeDescription}
          imageUrl={item.coffeeImageUrl}
        />
      </li>
    )));
  }

  return (
    <Modal show fullscreen>
      <Modal.Header>
        <Modal.Title>
          <Button variant="outline-primary" aria-label="Back" onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} />
            { !isLoading && (
              <span>
                &nbsp;
                {brandName}
              </span>
            )}
          </Button>
        </Modal.Title>
      </Modal.Header>
      { isLoading && (
        <Modal.Body className="brandDetail">
          <Spinner animation="border" size="lg" />
        </Modal.Body>
      )}
      { !isLoading && (
        <Modal.Body className="brandDetail">
          <Container>
            <Row>
              <Col>
                <div>
                  <div className="label">Merk</div>
                  <div className="value">{brandName}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="label">Gevestigd</div>
                  <div className="value">{brandLocation}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <div className="label">Soorten Koffie</div>
                  <div className="value">{products && products.length}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="label">Gemiddelde Score</div>
                  <div className="value">
                    <Rating
                      rating={brandAverageRating}
                      readOnly
                    />
                    <span className="rating small">{brandAverageRating}</span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <div className="label">Bio</div>
                  <div className="value">{brandBio}</div>
                </div>
              </Col>
            </Row>
            <Row className="logo">
              <Image src={brandImageUrl} />
            </Row>
            <Row>
              <Col>
                <div>
                  <div className="label">Onze Koffies</div>
                </div>
              </Col>
            </Row>
            <Row className="products">
              <ul>
                {coffeeItems}
              </ul>
            </Row>
          </Container>
        </Modal.Body>
      )}
    </Modal>
  );
}

export default BrandDetail;
