import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Modal,
  Button,
  Container,
  Row,
  Form,
  Spinner,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { useAuth } from '../../context/Auth';
import { searchBrands } from '../../services/api.ts';

import './style.css';

function useFetchData() {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { authToken } = useAuth();

  const fetch = (query) => {
    setLoading(true);
    searchBrands(authToken, query)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        setLoading(false);
        setData(body);
      })
      .catch(() => {
        console.log('error!'); // TODO
      });
  };

  return {
    fetch,
    isLoading,
    data,
  };
}

function Search() {
  const [query, setQuery] = useState('');
  const { fetch, isLoading, data } = useFetchData();
  const history = useHistory();

  const doSearch = (event) => {
    event.preventDefault();
    fetch(query);
  };

  return (
    <Modal className="search-modal" show fullscreen>
      <Modal.Header>
        <Modal.Title>
          <Button variant="outline-primary" aria-label="Back" onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <Form onSubmit={doSearch}>
            <Form.Control
              type="keywords"
              placeholder="Zoek op merknaam..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Form>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { isLoading && <Spinner animation="border" size="sm" /> }
        { !isLoading && (
        <Container>
          {data.map((item) => (
            <Row key={item.id}>
              <Link to={`/brand/${item.id}`}>{item.name}</Link>
            </Row>
          ))}
        </Container>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default Search;
