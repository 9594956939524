import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import Header from '../../components/header';
import Footer from '../../components/footer';
import CoffeeCard from '../../components/CoffeeCard';

import { useAuth } from '../../context/Auth';
import { fetchFeed } from '../../services/api.ts';

import './style.css';

function useFetchData() {
  const [isLoading, setLoading] = useState(false);
  const [isLoggedOut, setLoggedOut] = useState(false);
  const [data, setData] = useState([]);
  const { authToken } = useAuth();
  useEffect(() => {
    setLoading(true);
    fetchFeed(authToken)
      .then((res) => {
        if (!res.ok) {
          if (res.status === 401) {
            console.log('token expired');
            setLoggedOut(true);
          } else {
            throw new Error(res.statusText);
          }
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        setLoading(false);
        setData(body);
      })
      .catch(() => {
        console.log('error!'); // TODO
      });
  }, [authToken]);
  return {
    isLoading,
    isLoggedOut,
    data,
  };
}

function FeedOverview() {
  const { isLoading, isLoggedOut, data } = useFetchData();
  const history = useHistory();

  const showDetail = (item) => {
    console.log(`show detail item: ${item.id}`);
    history.push(`/detail/${item.id}`);
  };

  if (isLoggedOut) {
    return <Redirect to="/logout" />;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Header />
      <div className="feed-container">
        {data.map((item) => (
          <CoffeeCard
            key={item.id}
            coffeeBrand={item.brand.name}
            coffeeName={item.name}
            username={item.user.username}
            imageUrl={item.image}
            comment={item.comment}
            openDateTime={new Date(item.dateAdd)}
            rating={item.rating}
            onClick={() => showDetail(item)}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default FeedOverview;
