import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../context/Auth';

export default function Logout() {
  const { clearAuthResult } = useAuth();

  useEffect(() => {
    clearAuthResult();
  });

  return <Redirect to="/" />;
}
