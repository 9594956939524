import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

function IconAttribution(props) {
  const { keyword, author } = props;

  return (
    <div className="iconAttribution">
      <a
        href={`https://www.flaticon.com/free-icons/${keyword}`}
        title={`${keyword} icons`}
        target="_blank"
        rel="noreferrer"
      >
        {keyword.charAt(0).toUpperCase() + keyword.slice(1)}
        &nbsp;icons created by&nbsp;
        {author}
        &nbsp;- Flaticon
      </a>
    </div>
  );
}

IconAttribution.propTypes = {
  keyword: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
};

export default IconAttribution;
